import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

const Template = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                setContent(response.data.message.name);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );
    }, []);
    return (

        <div className="container">

            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Template</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Publish</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Settings</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <header className="jumbotron">
                        <h3>{content}</h3>
                    </header>
                </div>
                <div className="tab-pane" id="tabs-2" role="tabpanel">
                    <p>Second Panel</p>
                </div>
                <div className="tab-pane" id="tabs-3" role="tabpanel">
                    <p>Third Panel</p>
                </div>
            </div>
        </div>


    );
};

export default Template;






