import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { renderToString } from 'react-dom/server'
import ReactHtmlParser from 'react-html-parser'; 
import $, { data } from 'jquery'; 
import axios from 'axios';

import ReactDom from 'react-dom'

import authHeader from "../services/auth-header";

const AddVariable_ = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
 
 UserService.getText().then(
    (response) => {
    //   console.log()
    setContent(response.data.text);
    //   if(props.email === true){
      
    //   }
    //   if(props.first_name  === true){
    //       setContent(response.data[0].first_name);
    //       }
    },
    (error) => {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      setContent(_content);
    }
  );
    // UserService.getCSVData().then(
    //   (response) => {
    //     // console.log(response.data[0].email)
    //     if(props.email === true){
    //     setContent(response.data[0].email);
    //     }
    //     if(props.first_name  === true){
    //         setContent(response.data[0].first_name);
    //         }
    //   },
    //   (error) => {
    //     const _content =
    //       (error.response && error.response.data) ||
    //       error.message ||
    //       error.toString();

    //     setContent(_content);
    //   }
    // );
    
  }, []);

  return (
    content
//   <p>Regex to get string between curly {props.somProp.email}</p>
  );
};




// const GetContent = (props) => {
//     const [content, setContent] = useState("");
//     const [variable, setVariable] = useState("");
//     const [fields, setFields] = useState([]);
//     useEffect(() => {
   

//         const text = "Regex to get string between curly braces {email} want what's between the curly {first_name}"
//         var result = text.match(/{([^}]+)}/g)
//         .map(res => res.replace(/{|}/g , ''))
//         // console.log(result)
//         // this.setState({
//         //     fields: result
//         // })
//         setFields(result)
//         UserService.getCSVData().then(
//           (response) => {
//             // console.log(response.data[0].email)
            
//             let result_ = response.data.map((val, index)=>{
//                 var json_arr = {};
//                 result.map(record =>{
//                         json_arr[record] = val[record]
//                 })
//                 return json_arr
//             })
//             // console.log(result_)
//             // this.setState({
//             //     variables: result_
//             // })
//             setVariable(result_[0])
    
                
//           },
//           (error) => {
//             const _content =
//               (error.response && error.response.data) ||
//               error.message ||
//               error.toString();
    
//             // setContent(_content);
//           })
          
//     }, []);
  
//     console.log(variable)
//     return (
//         <>
    
//         <AddVariable_ somProp={variable} />
//         </>
//     );
//   };
  


class GetContent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { content: "", full_content:"",fields: [], variables: [] };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.testVariable= ""
    }
    
    componentDidMount() {


        // const text = "Regex to get string between curly braces {email} want what's between the curly {first_name}"
        // var result = text.match(/{([^}]+)}/g)
        // .map(res => res.replace(/{|}/g , ''))
        // console.log(result)
        UserService.getCSVFields().then(
          (response) => {            
            // let result_ = response.data.map((val, index)=>{
            //     var json_arr = {};
            //     result.map(record =>{
            //             json_arr[record] = val[record]
            //     })
            //     return json_arr
            // })
            
            this.setState({
                fields: response.data
            })
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          })
          
        
        UserService.getCSVData().then(
          (response) => {
            // console.log(response.data[0].email)
            // console.log(this.state.fields)
            let result_ = response.data.map((val, index)=>{
                var json_arr = {};
                this.state.fields.map(record =>{
                        json_arr[record] = val[record]
                })
                return json_arr
            })
            console.log(result_)
            this.setState({
                variables: result_
            })
            // result = result.map(function(val, index){
            //     // console.log(val)
            //     var json_arr = {};
            //     json_arr[val] = response.data[index][val];
                
            // })
            // console.log(result_)
            // if(props.email === true){
            // // setContent(response.data[0].email);
            // }
            // if(props.first_name  === true){
            //     // setContent(response.data[0].first_name);
            //     }
    
    
                
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
    
            // setContent(_content);
          })
          
          UserService.getText().then(
            (response) => {
            //   console.log()
            this.setState({
                content : response.data.text.substring(1, response.data.text.length - 1)
            });
            //   if(props.email === true){
              
            //   }
            //   if(props.first_name  === true){
            //       setContent(response.data[0].first_name);
            //       }
            },
            (error) => {
              const _content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
        
            //   setContent(_content);
            }
          );
        // UserService.getText().then(
        //     (response) => {
        //       // console.log(response.data[0].email)
        //     //   if(props.emails === true){
        //       this.setState({
        //         content: response.data.user.substring(1, response.data.user.length - 1)
        //       })
        //       this.testVariable = response.data.user.substring(1, response.data.user.length - 1)
        //     //   ReactDom.render(response.data.user.substring(1, response.data.user.length - 1), document.getElementById('editor_value'));

              
        //     //   }
        //     },
        //     (error) => {
        //       const _content =
        //         (error.response && error.response.data) ||
        //         error.message ||
        //         error.toString();
      
        //         this.setState({
        //             content: _content
        //           })
        //     }
        //   );
      
      // this.setState({full_content: value__})
    }
  
    handleChange(event) {
    //   alert($("#editor_value").val())
      // this.setState({ content: event.target.value });
    }
  
    handleSubmit(event) {
        // $('#editor_value').html(this.state.content);
        // alert(this.state.content)
       
       
          
          var formData = {
            variable:  [this.state.variables[0]],
            text: this.state.content
          };

          console.log(this.state.variables)

        //   const formData= {
        //     "text": $("#editor_value").val()
        // }
        // console.log(formData)
            const config = {
                headers: authHeader()
              };
            axios.post("http://localhost:14000/main", formData, config).then((response) => {
                console.log(response.data[0]);
                this.setState({
                    full_content: response.data[0]
                })
              });
      event.preventDefault();
    }
  
    render() {
        // console.log( {"value": this.state.fields})
        var fields =  this.state.fields
        // console.log(fields)
        // ReactDom.render(this.state.content, document.getElementById("main"));

        return ( 
                  
<div className="row">
  <div className="col-sm-12">
  {/* <div><h2>{this.state.content}</h2></div> */}
  <div>{this.state.full_content}</div>
{/* <AddVariable_  email /> */}
<div id="editor_value" style={{visibility: "hidden"}}></div>
    
    <form onSubmit={this.handleSubmit}>
        {/* <h2>{content}</h2> */}
        {/* <div dangerouslySetInnerHTML={{ __html: htmlcontent }} /> */}
  
        {/* <textarea
          rows="20"
          cols="80"
          value={this.state.content}
          onChange={this.handleChange}
        /> */}
        <br />
        <input type="submit" value="Submit" />
      </form>
  </div>
  </div>
    
            //  <AddVariable_ somProp={this.state.fields} />
        ) 
            // this.state.variables
        // somProp={myArray}
         
      // const ShareThis = ({ text }) => {
      //     return `<div class="one-line">${text}</div>`;
      //   };
        
      //   const content_ = `
      //   <p>Text 1</p>
      //   !#ShareButton
      //   <p>Text 2</p>
      //   `;
        
      //   const htmlcontent = content_.replace(
      //     /!#ShareButton/g,
      //     ShareThis({ text: "Hello ok" })
      //   );
      
    //   return (
          

  
    //   );
    
    }
  }
// const GetContent = (props) => {
//     const [content, setContent] = useState("");
  
//     useEffect(() => {
  
//       UserService.getText().then(
//         (response) => {
//           // console.log(response.data[0].email)
//         //   if(props.emails === true){
//           setContent(response.data.user.substring(1, response.data.user.length - 1));
          
//         //   }
//         },
//         (error) => {
//           const _content =
//             (error.response && error.response.data) ||
//             error.message ||
//             error.toString();
  
//           setContent(_content);
//         }
//       );
//     }, []);

    
//     return (
//         render(content)
        
//     //  <div>
//     //     {/* <AddVariable_  email/> */}
//     //     {/* s = s.replaceAll("\"(.+)\"", "$1"); */}
//     //     {/* <p><AddVariable_ first_name/> </p> */}
         
//     //  </div>
//     );
//   };
export default GetContent;


// export default AddVariable;






