import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import $, { data } from 'jquery'; 
import axios from 'axios';

import authHeader from "../services/auth-header";

// import Quill from 'quill';
// import "../../public/script.js"
import ReactQuill, { Quill } from 'react-quill';
var Embed = Quill.import('blots/embed');
const AddVariable_ = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {

    UserService.getCSVData().then(
      (response) => {
        // console.log(response.data[0].email)
        if(props.emails === true){
        setContent(response.data[0].email);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <h3>{content}</h3>
  );
};



class AddNewVariable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: "", full_content:"", fields: [], value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {

    class TemplateMarker extends Embed {
        static create(value) {
            let node = super.create(value);
            
            // node.setAttribute('class', 'badge badge-' + value.colour);
            node.setAttribute('class', 'badge badge-warning');
            //Set up the badge, and badge colour
            
            node.setAttribute('data-marker', value.marker);
            //The marker is the $ rel_table[id] reference
            
            node.setAttribute('data-title', value.title);
            //
            node.setAttribute("contenteditable", true);
            node.innerHTML = value.title;
            //The title is what the user sees in their editor
            
            return node;
        }
        
        static value(node) {
            return {
                marker: node.getAttribute('data-marker'),
                title: node.getAttribute('data-title'),
            };
        }
    }
    
    TemplateMarker.blotName = 'TemplateMarker';
    TemplateMarker.tagName = 'span';
    
    Quill.register({
        'formats/TemplateMarker': TemplateMarker
    });
    
    var toolbarOptions = ['bold', 'italic', 'underline', 'strike'];
    
    var options = {
      
      toolbar: {
        container: '#toolbar',  
      },
      placeholder: 'Text Editor',
      theme: 'snow'
    };
    
    var quill = new Quill('#editor', options);
    $('.ql-insertCustomTags').on('change', function() {
        let range = quill.getSelection(true);
        
        quill.insertEmbed(
            range.index,
            //Insert the TemplateMarker in the same range as the cursor is
            
            'TemplateMarker',
            //This is the name of the Embed
            
            {
                colour: $(this).find(':selected').data('colour'),
                marker: $(this).find(':selected').data('marker'),
                title: $(this).find(':selected').data('title')
            },
            //These are the variables to enter
        );
        
        quill.insertText(range.index + 1, ' ', Quill.sources.USER);
        //Add a space after the marker
        
        quill.setSelection(range.index + 2, Quill.sources.SILENT);
        //Take the cursor to the end of the inserted TemplateMarker
        
        $(this).val("");
        //Reset the dropdown
    });
    quill.on('text-change', function(delta, oldDelta, source) {
        var delta = quill.getContents();
        var qdc = new window.QuillDeltaToHtmlConverter(delta.ops, window.opts_ || {});

        qdc.renderCustomWith(function(customOp, contextOp){
            if (customOp.insert.type === 'TemplateMarker') {
                let val = customOp.insert.value;
                return val.marker;
            }
        });
        
        var html = qdc.convert();
        $("#editor_value").val(JSON.stringify(delta));

    });
     
    UserService.getCSVFields().then(
      (response) => {
         console.log(response.data)
        this.setState({fields: response.data})
        // setContent(response.data[0]);
        
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

          // this.setState({fields: response.data[0]})

      }
    );
    // let editor_render = this.state.text
    
    // this.setState({full_content: value__})
  }

  handleChange(event) {
    alert($("#editor_value").val())
    // this.setState({ content: event.target.value });
  }

  handleSubmit(event) {
    alert($("#editor_value").val())
  // Create an object of formData
  console.log($("#editor_value").val())
// 	const formData = new FormData();
	
// 	// Update the formData object
// 	formData.append("text", "text"
// );
const formData= {
    "text": $("#editor_value").val()
}
	const config = {
        headers: authHeader()
      };
	axios.post("http://localhost:14000/add-text", formData, config).then((response) => {
        console.log(response.data);
      });

    event.preventDefault();
  }

  render() {
    

    // const ShareThis = ({ text }) => {
    //     return `<div class="one-line">${text}</div>`;
    //   };
      
    //   const content_ = `
    //   <p>Text 1</p>
    //   !#ShareButton
    //   <p>Text 2</p>
    //   `;
      
    //   const htmlcontent = content_.replace(
    //     /!#ShareButton/g,
    //     ShareThis({ text: "Hello ok" })
    //   );
    
    return (
        
<div className="row">
  <div className="col-sm-12">
  <div><h2>{this.state.contentT}</h2></div>
<select className="custom-select ql-insertCustomTags">
		<option value="">Fields</option>
    {
           this.state.fields.map(function(item, i){
            
            // var data = "<%= people."+ item+"%>";
            
           return(  
           <>
           
           <option  data-marker={item} data-title={item} data-colour="warning">{item}</option>
             </>
           )
           })
         }
         {/* <option value="first_name" data-marker="<AddVariable_  emails />" data-title="FIRST NAME" data-colour="warning">First Name</option>
             <option value="last_name" data-marker="<AddVariable_  emails />" data-title="LAST NAME" data-colour="warning">Last Name</option>
             <option value="full_name" data-marker="<AddVariable_  emails />" data-title="FULL NAME" data-colour="warning">Full Name</option>
             */}
 </select>
  
<div id="editor"></div>

<div id="editor_value" style={{visibility: "hidden"}}></div>
   
    <form onSubmit={this.handleSubmit}>
        {/* <h2>{content}</h2> */}
        {/* <div dangerouslySetInnerHTML={{ __html: htmlcontent }} /> */}
        <div>Provide a course overview <AddVariable_  emails /></div>
        {/* <textarea
          rows="20"
          cols="80"
          value={this.state.content}
          onChange={this.handleChange}
        /> */}
        <br />
        <input type="submit" value="Submit" />
      </form>
  </div>
</div> 

    );
  
  }
}

export default AddNewVariable;


// export default AddVariable;






