import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

const AddTemplate = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                setContent(response.data.message.name);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );
    }, []);
    return (

        <div className="container">
            <form>
                <div className="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Template Name"/>
                        <small id="emailHelp" className="form-text text-muted">Enter a name for your template. Only alphanumeric, spaces, and -,_: characters allowed.</small>
                </div>
                <button type="submit" className="btn btn-primary">Create</button>
            </form>
        </div>


    );
};

export default AddTemplate;






