import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";

 import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";


import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import FileUpload from "./components/Fileupload";
import AddVariable from './components/AddVariable'
import AddNewVariable from './components/AddNewVariable'
import AddTemplate from './components/AddTemplate'
import Templates from './components/Template'
import GetContent from "./components/ListContent"
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    // if (currentUser) {
    //   setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
    //   setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    // } else {
    //   setShowModeratorBoard(false);
    //   setShowAdminBoard(false);
    // }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    
    <div>
            <nav id="sidebar">
                <div className="sidebar-header">
                    <h3>Content</h3>
                </div>
                Template
                <ul className="list-unstyled components">
                    
                    {currentUser && (
                    <li>
                    <Link to={"/templates"} className="nav-link">
                        Templates
                    </Link>
                    </li>
                     )}
                     {currentUser && (
                    <li>
                    <Link to={"/home"} className="nav-link">
                        Home
                    </Link>
                    </li>
                     )}
                     {currentUser && (
                    <li>
                    <Link to={"/add-template"} className="nav-link">
                    AddTemplate
                    </Link>
                    </li>
                     )}
                    {currentUser && (
                    <li>
                    <Link to={"/add-variable"} className="nav-link">
                      Add variable
                    </Link>
                    </li>
                         )}
                    {currentUser && (
                    <li>
                    <Link to={"/get-content"} className="nav-link">
                      Get content
                    </Link>
                    </li>
                     )}
                    {currentUser && (
                    <li>
                    <Link to={"/file-upload"} className="nav-link">
                      File upload
                    </Link>
                    </li>
                    )}
                    {currentUser && (
                    <li>
                    <Link to={"/add-new-variable"} className="nav-link">
                      Add new variable
                    </Link>
                    </li>
                    )}
                    
                </ul>
            </nav>

       
      <nav className="navbar navbar-expand" >
        {/* <Link to={"/"} className="navbar-brand">
          Content
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link to={"/add-variable"} className="nav-link">
              add variable
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/get-content"} className="nav-link">
              get content
            </Link>
          </li>

          <li className="nav-item">
            <Link to={"/file-upload"} className="nav-link">
              file upload
            </Link>
          </li>
          {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )}
        </div> */}

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.name}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user" element={<BoardUser />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/file-upload" element={<FileUpload />} />
          <Route path="/add-variable" element={<AddVariable />} />
          <Route path="/get-content" element={<GetContent />} />
          <Route path="/add-new-variable" element={<AddNewVariable />} />
          <Route path="/add-template" element={<AddTemplate />} />
          <Route path="/templates" element={<Templates />} />
        </Routes>
      </div>
      


      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
};

export default App;



