import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://localhost:14000/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getCSVFields = () => {
  return axios.get(API_URL + "csv-file-fields", { headers: authHeader() });
};
const getUserBoard = () => {
  return axios.get(API_URL + "profile", { headers: authHeader() });
};

const getCSVData = async () => {
  return await axios.get(API_URL + "csv-file-upload", { headers: authHeader() });
};
const getText = () => {
  return axios.get(API_URL + "get-text", { headers: authHeader() });
};
const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getCSVData,
  getCSVFields,
  getText
};